import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"create_comments"},[_c(VForm,[_c(VContainer,[_c('h1',[_vm._v("Create Comment")]),_c(VRow,[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Name","outlined":""},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Since","outlined":""},model:{value:(_vm.form_data.since),callback:function ($$v) {_vm.$set(_vm.form_data, "since", $$v)},expression:"form_data.since"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VFileInput,{attrs:{"color":"deep-purple accent-4","counter":"","placeholder":"File input","prepend-icon":"","outlined":"","show-size":1000},scopedSlots:_vm._u([{key:"selection",fn:function({ index, text }){return [(index < 2)?_c(VChip,{attrs:{"color":"deep-purple accent-4","dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}]),model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VTextField,{attrs:{"placeholder":"Comment","outlined":""},model:{value:(_vm.form_data.comment),callback:function ($$v) {_vm.$set(_vm.form_data, "comment", $$v)},expression:"form_data.comment"}})],1),_c(VBtn,{staticClass:"ma-2 white--text",attrs:{"loading":_vm.loading_form,"disabled":_vm.loading_form,"color":"blue-grey","outlined":""},on:{"click":_vm.create_comment}},[_vm._v(" Add "),_c(VIcon,{attrs:{"right":"","dark":""}},[_vm._v(" mdi-cloud-upload ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }